import Mock from "mockjs"
//登录接口
Mock.mock("https://www.springcity.top:9999/login","post",(req)=>{
    const {name,password}=JSON.parse(req.body)
    if(name==="admin"&&password==="111111"){
        return{
            code:200,
            success:true,
            message:"登录成功",
            token:"zidingyitokenzhengchangdoushishengchengde",//令牌
            nickname:"大哥"
        }
    }else{
        return{
            code:100,
            success:false,
            message:"账号或者密码不正确"
        }
    }
})
