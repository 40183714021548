import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routerMap'
Vue.use(VueRouter)//vue量身定制的router使用时要注册
 


const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router